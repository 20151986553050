import React from "react"

class Book extends React.Component {
  constructor(props) {
    super(props)
    this.state = ({
      title: this.props.title,
      author: this.props.author,
      link: this.props.link,
      imageLink: this.props.imageLink,
    })
  }

  render() {
    return (
      <div className="book">
        <a
          href={this.state.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={this.state.imageLink} alt=""></img>
        </a>
      </div>
    )
  }
}

export default Book
