import React from "react"
import { Link } from "gatsby"
import Book from "../components/Book"
import "../styles/books.css"

let bookList = require('../../static/books.json')
var books = []
for (var i = 0; i < bookList.length; ++i) {
  let book = bookList[i]
  books.push(
    <Book key={i} title={book.title} author={book.author} link={book.link} imageLink={book.imageLink}></Book>
  )
}

class BookClub extends React.Component {
  render() {
    return (
      <div id="bookClub">
        <h1 id="aninasBookClub">Anina's Book Club</h1>
        <p><Link to="/" className="link">Home</Link></p>
        <div id="books">
          {books}
        </div>
      </div>
    )
  }
}

export default BookClub
